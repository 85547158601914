import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { IMatchStats, ITeam } from "~/utils/api.interfaces.enums";
import { useTheme } from "~/utils/theme-provider";
import Chart from "~/components/charts/chart";

export default function ChartGoalDifference({
  matches,
  activeTeam,
  limitMatches,
  graphHeight,
}: {
  matches: IMatchStats[];
  activeTeam?: ITeam;
  limitMatches?: boolean;
  graphHeight?: number;
}) {
  const dateOptions = {
    // weekday: "long",
    // year: "2-digit",
    month: "short",
    day: "numeric",
  };
  const dateFormatter = (params: any) => {
    return new Date(params).toLocaleDateString("en", dateOptions);
  };
  function sortByDateAsc(a, b) {
    return new Date(a.scheduledAtUtc) - new Date(b.scheduledAtUtc);
  }
  const { t, ready, i18n } = useTranslation("translation");

  const [theme] = useTheme();

  const componentMatches = matches?.slice(0); //make a clone of the array
  const [sortedMatches, setSortedMatches] = useState<IMatchStats[]>(
    componentMatches?.sort(sortByDateAsc)
  );

  useEffect(() => {
    if (activeTeam && matches) {
      setSortedMatches(componentMatches?.sort(sortByDateAsc));
    }
  }, [matches]);
  if (sortedMatches?.length === 0) {
    setSortedMatches([
      {
        id: "cc3c29f0-f24d-435f-93c4-441fba3c7792",
        scheduledAtUtc: "2023-08-22T19:00:21.271Z",
        isAway: false,
        score: {
          team: 4,
          opponent: 0,
        },
        opponent: {
          id: "605801C5-98DC-4FAD-8E36-A9E2492F3948",
          name: "Snorlax",
          nameAbbreviation: "SNO",
          logoUrl: "",
          organization: {
            id: "048b8768-6189-422f-98a7-33735f0b33c7",
            name: "Footy Default",
            shortName: "fd",
            logoUrl: "",
            countryCode: "nl",
            type: "default",
          },
        },
        participants: ["5d12cf32-f59a-4ec6-b7bc-98637d115cb3"],
        goals: [],
        viewsCount: 1,
        totalCheers: 0,
        totalComments: 0,
        averageMatchRating: 0,
      },
      {
        id: "3dc19ee2-c439-4c98-bfcd-ead972340bbe",
        scheduledAtUtc: "2023-08-22T19:00:34.833Z",
        isAway: false,
        score: {
          team: 2,
          opponent: 0,
        },
        opponent: {
          id: "605801C5-98DC-4FAD-8E36-A9E2492F3948",
          name: "Snorlax",
          nameAbbreviation: "SNO",
          logoUrl: "",
          organization: {
            id: "048b8768-6189-422f-98a7-33735f0b33c7",
            name: "Footy Default",
            shortName: "fd",
            logoUrl: "",
            countryCode: "nl",
            type: "default",
          },
        },
        participants: [
          "5d12cf32-f59a-4ec6-b7bc-98637d115cb3",
          "pre-b2756ff1-5c5f-4e4f-9d0c-589cc6f96a06",
        ],
        goals: [],
        viewsCount: 2,
        totalCheers: 0,
        totalComments: 0,
        averageMatchRating: 5,
      },
    ]);
  }

  let total = 0;
  const totalDifference = sortedMatches?.reduce(
    (a, b) => a + (b?.score?.team || 0) - (b?.score?.opponent || 0),
    0
  );

  return (
    <div className="w-full text-gray-900">
      <div
        className={`${
          matches?.length === 0 ? "opacity-20  pointer-events-none" : ""
        }`}
      >
        <Chart
          options={{
            colors: ["#68F3CB", "#A855F7"],
            chart: {
              toolbar: {
                show: false,
              },
              zoom: {
                enabled: false,
              },
              sparkline: {
                enabled: false,
              },
            },
            annotations: {
              yaxis: [
                {
                  y: 0,
                  strokeDashArray: 0,
                  borderColor: "#A855F7",
                  fillColor: "#A855F7",
                  opacity: 0.8,
                  offsetX: 0,
                  offsetY: 0,
                },
              ],
            },
            fill: {
              colors: ["#68F3CB", "#A855F7"],
              opacity: 1,
              type: ["gradient", "solid"],
              gradient: {
                inverseColors: false,
                shade: "light",
                type: "vertical",
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100],
              },
              pattern: {
                style: "verticalLines",
                width: 6,
                height: 6,
                strokeWidth: 2,
              },
            },
            // fill: {
            //   opacity: [0.85, 0.25, 1],
            //   gradient: {
            //     inverseColors: false,
            //     shade: "light",
            //     type: "vertical",
            //     opacityFrom: 0.85,
            //     opacityTo: 0.55,
            //     stops: [0, 100, 100, 100],
            //   },
            // },
            plotOptions: {
              bar: {
                colors: {
                  ranges: [
                    {
                      from: -100,
                      to: 0,
                      color: "#653394",
                    },
                    {
                      from: 0,
                      to: 100,
                      color: "#A855F7",
                    },
                  ],
                },
              },
            },
            grid: {
              show: true,
              borderColor: theme === "dark" ? "#2A3342" : "#EEF0F3",
              strokeDashArray: 0,
              position: "back",
              xaxis: {
                lines: {
                  show: true,
                },
              },
              yaxis: {
                lines: {
                  show: true,
                },
              },
            },
            dataLabels: {
              enabled: false,
            },
            legend: { show: !limitMatches, horizontalAlign: "left" },
            stroke: {
              show: true,
              curve: "smooth",
              lineCap: "butt",
              colors: undefined,
              width: [3, 0],
              dashArray: 0,
            },

            yaxis: {
              opposite: true,
              labels: {
                show: true,
                style: { colors: theme === "dark" ? "#EEF0F3" : "#000" },
              },
              tickAmount:
                totalDifference > 10 ? Math.floor(totalDifference / 4) : 3,
              min: totalDifference < -5 ? totalDifference - 3 : -5,
              max: totalDifference > 10 ? totalDifference + 3 : 10,
            },
            xaxis: {
              axisBorder: {
                show: true,
              },
              axisTicks: {
                show: !limitMatches,
              },
              tickAmount: limitMatches
                ? 4
                : sortedMatches?.length > 15
                ? 25
                : sortedMatches?.length,
              min: 1,
              type: "category",
              categories: sortedMatches,
              tooltip: {
                enabled: false,
              },
              labels: {
                show: !limitMatches,
                rotate: 0,
                formatter: function (value: IMatchStats) {
                  if (value) {
                    // const label = value.score.team - value.score.opponent;
                    const goalDifference =
                      value.score?.team - value.score?.opponent;
                    const label: string[] = [
                      dateFormatter(value.scheduledAtUtc),
                      value.opponent.nameAbbreviation,
                      goalDifference > 0
                        ? "+" + goalDifference.toString()
                        : goalDifference.toString(),
                    ];
                    return label;
                  }
                },
                style: { colors: theme === "dark" ? "#EEF0F3" : "#000" },
              },
            },
            tooltip: {
              marker: { show: false },
              enabled: true,
              x: {
                formatter: function (
                  value,
                  { series, seriesIndex, dataPointIndex, w }
                ) {
                  const label =
                    w.globals.categoryLabels[dataPointIndex][0] +
                    " &middot; " +
                    w.globals.categoryLabels[dataPointIndex][1] +
                    "  &middot;  " +
                    w.globals.categoryLabels[dataPointIndex][2] +
                    " ";
                  return label;
                },
              },
            },
          }}
          series={[
            {
              type: "area",
              name: t("statsPages.graphTitleGoalDifference"),
              data: sortedMatches?.map((match: IMatchStats) => {
                return (total += match.score?.team - match.score?.opponent);
              }),
            },
            {
              name: t("statsPages.graphTitleGoalDifferencePerMatch"),
              type: "column",
              data: sortedMatches?.map((match: IMatchStats) => {
                if (match?.score === undefined) {
                  return 0;
                }
                return match.score?.team - match.score?.opponent;
              }),
            },
          ]}
          width="100%"
          height={graphHeight ? graphHeight : "222"}
        />
      </div>
      {matches?.length === 0 && (
        <div className="right-0 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          {" "}
          <div className="font-bold text-2xl text-center mt-8 dark:text-gray-200">
            No data
          </div>
          <div className="text-center text-gray-500 dark:text-gray-200 text-sm mb-10 max-w-xs m-auto">
            Use the match feature in the Mingle Sport App to generate this
            statistics graph
          </div>
        </div>
      )}
    </div>
  );
}
