import { Link, useParams } from "@remix-run/react";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { IMatchStats, ITeam } from "~/utils/api.interfaces.enums";
import { MixPanel } from "~/utils/MixPanel";

export default function TeamForm({
  matches,
  activeTeam,
}: {
  matches: IMatchStats[];
  activeTeam?: ITeam;
}) {
  const params = useParams();

  const dateOptions = {
    // weekday: "long",
    // year: "2-digit",
    month: "short",
    day: "numeric",
  };
  const dateFormatter = (params: any) => {
    return new Date(params).toLocaleDateString("en", dateOptions);
  };
  const { t, ready, i18n } = useTranslation("translation");
  function sortByDateAsc(a, b) {
    return new Date(b.scheduledAtUtc) - new Date(a.scheduledAtUtc);
  }
  const componentMatches = matches?.slice(0); //make a clone of the array
  const [sortedMatches, setSortedMatches] = useState<IMatchStats[]>(
    componentMatches?.sort(sortByDateAsc)
  );

  useEffect(() => {
    if (activeTeam && matches) {
      setSortedMatches(componentMatches?.sort(sortByDateAsc));
    }
  }, [matches]);
  return (
    <>
      <div className="card ">
        <div className="card-body p-0">
          <h2 className="font-bold text-xl p-6 pb-0">
            {t("statsPages.titleTeamForm")}
          </h2>
          <h3 className="text-sm font-normal text-gray-400 dark:text-gray-200 px-6">
            {t("statsPages.titleResultsLastMatches")}
          </h3>
          <table className="table w-full ">
            {/* head */}

            <tbody>
              {sortedMatches?.length > 0 ? (
                sortedMatches?.map((match: IMatchStats, index: number) => {
                  if (index < 4) {
                    return (
                      <tr key={match.id + index} className="border-0">
                        <td className="border-0 px-0 py-0">
                          <div
                            className={`flex justify-start gap-2 items-center `}
                          >
                            <div className="text-xs font-normal text-gray-400 dark:text-gray-200 absolute left-6">
                              {dateFormatter(match.scheduledAtUtc)}
                            </div>
                            <div
                              className={`flex justify-center items-center w-full gap-2`}
                            >
                              <h2
                                className={`whitespace-normal overflow-ellipsis overflow-hidden w-16 font-bold
                                   ${
                                     match?.isAway
                                       ? "order-5"
                                       : "order-1 text-right "
                                   }`}
                              >
                                {activeTeam?.nameAbbreviation}
                              </h2>
                              <div
                                className={`avatar ${
                                  match?.isAway ? "order-4" : "order-2"
                                }`}
                              >
                                <div className="mask mask-circle w-8 h-8 text-white">
                                  {activeTeam?.logoUrl ? (
                                    <img
                                      src={activeTeam?.logoUrl}
                                      alt="Avatar "
                                      className="rounded-full"
                                    />
                                  ) : (
                                    <div className="w-8 h-8 bg-gray-200 rounded-full flex justify-center items-center">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-9 w-9"
                                        fill="currentColor"
                                        stroke="none"
                                        viewBox="0 0 45 44"
                                      >
                                        <path
                                          fill="currenColor"
                                          d="m36.694 8.426-12.567-5.06a3.14 3.14 0 0 0-2.325 0L9.236 8.426a3.142 3.142 0 0 0-1.98 2.923v8.391a.77.77 0 0 0 0 .189 28.606 28.606 0 0 0 4.022 12.037 28.953 28.953 0 0 0 9.707 8.297l.236.142c.527.312 1.131.47 1.744.455.648 0 1.282-.191 1.822-.55.44-.298.927-.597 1.445-.927a26.503 26.503 0 0 0 8.483-7.417 27.743 27.743 0 0 0 3.958-12.053.642.642 0 0 0 0-.173V11.35a3.144 3.144 0 0 0-1.979-2.923Zm-13.73 29.291c-1.57 0-5.544-3.315-7.853-5.987v-.22a4.713 4.713 0 0 1 2.136-1.493c1.8.828 3.74 1.308 5.718 1.415a14.525 14.525 0 0 0 5.64-1.477c.854.29 1.617.8 2.214 1.477a1.355 1.355 0 0 0 0 .267c-1.288 2.31-6.283 6.018-7.854 6.018Zm9.834-8.752-.125-.142a7.46 7.46 0 0 0-4.336-2.105c-.423.047-.83.187-1.194.408a8.731 8.731 0 0 1-4.178 1.163 9.563 9.563 0 0 1-4.32-1.084 3.47 3.47 0 0 0-.974-.346 6.565 6.565 0 0 0-4.131 1.744 3.733 3.733 0 0 0-.362.346 28.135 28.135 0 0 1-2.78-9.303v-8.313L22.965 6.29l12.567 5.044v8.313a25.968 25.968 0 0 1-2.734 9.319Z"
                                        />
                                        <path
                                          fill="currenColor"
                                          d="M22.965 11.003a7.852 7.852 0 0 0-7.256 4.85 7.86 7.86 0 0 0 5.724 10.713 7.85 7.85 0 0 0 8.062-3.34 7.86 7.86 0 0 0-6.53-12.223Zm0 12.571a4.711 4.711 0 0 1-4.622-5.634 4.715 4.715 0 0 1 6.425-3.435 4.713 4.713 0 0 1 1.53 7.689 4.712 4.712 0 0 1-3.333 1.38Z"
                                        />
                                      </svg>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div
                                className={`whitespace-nowrap text-xs font-bold text-white px-3 py-1 rounded-full w-15 text-center order-3 ${
                                  match.score.team > match.score.opponent
                                    ? "bg-green-700"
                                    : match?.score?.team <
                                      match?.score?.opponent
                                    ? "bg-red-500"
                                    : "bg-gray-400"
                                }`}
                              >
                                {match?.isAway ? (
                                  <>
                                    {match?.score?.opponent} -{" "}
                                    {match?.score?.team}
                                  </>
                                ) : (
                                  <>
                                    {match?.score?.team} -{" "}
                                    {match?.score?.opponent}
                                  </>
                                )}
                              </div>

                              <div
                                className={`avatar ${
                                  match?.isAway ? "order-2" : "order-4"
                                }`}
                              >
                                <div className="mask mask-circle w-8 h-8 text-white">
                                  {match?.opponent.logoUrl ? (
                                    <img
                                      src={match?.opponent.logoUrl}
                                      alt="Avatar "
                                      className="rounded-full"
                                    />
                                  ) : (
                                    <div className="w-8 h-8 bg-gray-200 rounded-full flex justify-center items-center">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-9 w-9"
                                        fill="currentColor"
                                        stroke="none"
                                        viewBox="0 0 45 44"
                                      >
                                        <path
                                          fill="currenColor"
                                          d="m36.694 8.426-12.567-5.06a3.14 3.14 0 0 0-2.325 0L9.236 8.426a3.142 3.142 0 0 0-1.98 2.923v8.391a.77.77 0 0 0 0 .189 28.606 28.606 0 0 0 4.022 12.037 28.953 28.953 0 0 0 9.707 8.297l.236.142c.527.312 1.131.47 1.744.455.648 0 1.282-.191 1.822-.55.44-.298.927-.597 1.445-.927a26.503 26.503 0 0 0 8.483-7.417 27.743 27.743 0 0 0 3.958-12.053.642.642 0 0 0 0-.173V11.35a3.144 3.144 0 0 0-1.979-2.923Zm-13.73 29.291c-1.57 0-5.544-3.315-7.853-5.987v-.22a4.713 4.713 0 0 1 2.136-1.493c1.8.828 3.74 1.308 5.718 1.415a14.525 14.525 0 0 0 5.64-1.477c.854.29 1.617.8 2.214 1.477a1.355 1.355 0 0 0 0 .267c-1.288 2.31-6.283 6.018-7.854 6.018Zm9.834-8.752-.125-.142a7.46 7.46 0 0 0-4.336-2.105c-.423.047-.83.187-1.194.408a8.731 8.731 0 0 1-4.178 1.163 9.563 9.563 0 0 1-4.32-1.084 3.47 3.47 0 0 0-.974-.346 6.565 6.565 0 0 0-4.131 1.744 3.733 3.733 0 0 0-.362.346 28.135 28.135 0 0 1-2.78-9.303v-8.313L22.965 6.29l12.567 5.044v8.313a25.968 25.968 0 0 1-2.734 9.319Z"
                                        />
                                        <path
                                          fill="currenColor"
                                          d="M22.965 11.003a7.852 7.852 0 0 0-7.256 4.85 7.86 7.86 0 0 0 5.724 10.713 7.85 7.85 0 0 0 8.062-3.34 7.86 7.86 0 0 0-6.53-12.223Zm0 12.571a4.711 4.711 0 0 1-4.622-5.634 4.715 4.715 0 0 1 6.425-3.435 4.713 4.713 0 0 1 1.53 7.689 4.712 4.712 0 0 1-3.333 1.38Z"
                                        />
                                      </svg>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <h2
                                className={`whitespace-normal overflow-ellipsis overflow-hidden w-16  ${
                                  match?.isAway
                                    ? "order-1 text-right"
                                    : "order-5"
                                }`}
                              >
                                {activeTeam?.organization?.type === "club" ? (
                                  <>
                                    {match?.opponent?.organization?.shortName}
                                  </>
                                ) : (
                                  <>{match?.opponent?.nameAbbreviation}</>
                                )}

                                <br />
                              </h2>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  }
                })
              ) : (
                <tr>
                  <th colSpan={7}>
                    {" "}
                    <div className="font-bold text-2xl text-center mt-8 dark:text-gray-200">
                      No data
                    </div>
                    <div className="text-center text-gray-500 dark:text-gray-200 text-sm mb-10 max-w-xs m-auto">
                      Use the match feature in the Mingle Sport App to generate
                      the leaderboard
                    </div>
                  </th>
                </tr>
              )}
            </tbody>
          </table>
          {matches?.length > 0 && (
            <Link
              to={`/activities/match/`}
              className="btn btn-default btn-outline  mt-auto m-6"
              onClick={(event) => {
                MixPanel.track(
                  "goalsScoredDetail_teamStats_tap_button_moreInfo"
                );
              }}
            >
              {t("statsPages.buttonMoreInfo")}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="h-2 w-2 ml-auto"
                viewBox="0 0 8 12"
              >
                <path
                  fill="currentColor"
                  d="m1.686.122 6.132 5.46a.53.53 0 0 1 0 .8l-6.132 5.46a.558.558 0 0 1-.777 0l-.748-.8a.54.54 0 0 1 0-.77l4.367-3.89a.53.53 0 0 0 0-.8L.201 1.732a.54.54 0 0 1 0-.77l.748-.8a.558.558 0 0 1 .737-.04Z"
                />
              </svg>
            </Link>
          )}
        </div>
      </div>
    </>
  );
}
